import React from 'react';

const Landing = React.lazy(() => import('./pages/Landing'))
const Story = React.lazy(() => import('./pages/Story'))
const Feature = React.lazy(() => import('./pages/Feature'))
const Faq = React.lazy(() => import('./pages/Faq'))
const Support = React.lazy(() => import('./pages/Support'))
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

const routes = [
    { path: '/', component: Landing, isWithoutLayout : true, exact : true },
    { path: '/story', component: Story, isWithoutLayout : true, exact : true },
    { path: '/feature', component: Feature, isWithoutLayout : true, exact : true },
    { path: '/faq', component: Faq, isWithoutLayout : true, exact : true },
    { path: '/support', component: Support, isWithoutLayout : true, exact : true },
    { component: PageError, isWithoutLayout : true, exact : false },
];

export default routes;